import React from 'react'
import './header.css'
import CTA from './CTA'
import TECH from '../../assets/tech.jpg'
import Headersocials from './Headersocials'
import JS from '../../assets/js.png'
import PYTHON from '../../assets/python.png'
const Header = () => {
    return (
        <header>
            <div className='container header__container'>
                <h5>Hi, I'm</h5>
                <h1>Nick!</h1>
                <h5 className='text-light'>Fullstack Developer</h5>
                <CTA />
                <Headersocials />
                <div className='me'>
                    <img src={JS} alt='JS' ></img>
                </div>
                <img src={PYTHON} className='py-image' alt='JS' ></img>
                <a href='#contact' className='scroll__down'>Scroll Down</a>
            </div>

        </header>
    )
}

export default Header