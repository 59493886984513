import React from 'react'
import './contact.css'
import { MdOutlineEmail } from 'react-icons/md'
import { RiMessengerLine } from 'react-icons/ri'
import { AiOutlineLinkedin } from 'react-icons/ai'
import { useRef } from 'react';
import emailjs, { sendForm } from 'emailjs-com'


const Contact = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_4x0kpi8', 'template_npgxbum', form.current, 'ojrS7WV7AvbEbVQbX')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    };

    return (
        <section id='contact'>
            <h5>Get In Touch!</h5>
            <h2>Contact Me</h2>
            <div className='container contact__container'>
                <div className='contact__options'>
                    <article className='contact__option'>
                        <MdOutlineEmail className='contact__option-icon' />
                        <h4>Email</h4>
                        <h5>nickjgastis@gmail.com</h5>
                        <a href='mailto:nickjgastis@gmail.com' target='_blank'>Send an Email</a>
                    </article>
                    <article className='contact__option'>
                        <AiOutlineLinkedin className='contact__option-icon' />
                        <h4>Linked In</h4>
                        <h5>nickjgastis@gmail.com</h5>
                        <a href='https://www.linkedin.com/in/nickgastis/' target='_blank'>View Profile</a>
                    </article>
                    <article className='contact__option'>
                        <RiMessengerLine className='contact__option-icon' />
                        <h4>Messenger</h4>
                        <h5>Nick Gastis</h5>
                        <a href='https://m.me/nick.gastis/' target='_blank'>Send a Message</a>
                    </article>
                </div>
                {/* END OF CONTACT OPTION  */}
                <div className='contact__form'>

                    <form ref={form} onSubmit={sendEmail}>
                        <input type="text" name='name' placeholder='Full Name' required />
                        <input type="email" name='email' placeholder='Your Email' required />
                        <textarea name='message' rows='7' placeholder='Your Message' required />
                        <button type='submit' className='btn btn-primary'>Send Message</button>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Contact