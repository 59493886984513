import React from 'react'
import './portfolio.css'
import ATLAS from '../../assets/atlas.png'
import NEXUS from '../../assets/nexus.png'
import TEA from '../../assets/tea.png'

const Portfolio = () => {
    return (
        <section id='portfolio'>
            <h5>My Projects</h5>
            <h2>Portfolio</h2>
            <div className='container portfolio__container'>
                <article className='portfolio__item'>
                    <div className='portfolio__item-image'>
                        <img src={NEXUS} alt='Nexus Impacts'></img>
                    </div>
                    <h3>Nexus Impacts</h3>
                    <div className="portfolio__item-p">
                        <p>Sustainability Brokerage</p>
                        <p>WordPress | Elementor Pro</p>
                    </div>
                    <div className="portfolio__item-cta">

                        {/* <a href='https://github.com/nickgastis/Atlas' className='btn' target='_blank'>GitHub</a> */}
                        <a href='https://nexusimpacts.com/' className='btn btn-primary' target='_blank'>Live Site</a>
                    </div>
                </article>
                <article className='portfolio__item'>
                    <div className='portfolio__item-image'>
                        <img src={ATLAS} alt='Atlas'></img>
                    </div>
                    <h3>Atlas</h3>
                    <div className="portfolio__item-p">
                        <p>Integrated AI coding assistant.</p>
                        <p>React | Flask | External API | OpenAi</p>
                    </div>
                    <div className="portfolio__item-cta">

                        <a href='https://github.com/nickgastis/Atlas' className='btn' target='_blank'>GitHub</a>
                        <a href='https://atlas-w8j1.onrender.com/' className='btn btn-primary' target='_blank'>Live Site</a>
                    </div>

                </article>
                <article className='portfolio__item'>
                    <div className='portfolio__item-image'>
                        <img src={TEA} alt='Atlas'></img>
                    </div>
                    <h3>The Tea</h3>
                    <div className="portfolio__item-p">
                        <p>Mock social media platform.</p>
                        <p>React | Flask | AWS</p>
                    </div>
                    <div className="portfolio__item-cta">

                        <a href='https://github.com/Reardon85/phase-4-social-media-app' className='btn' target='_blank'>GitHub</a>
                        <a href='https://the-tea.onrender.com/' className='btn btn-primary' target='_blank'>Live Site</a>
                    </div>
                </article>
            </div>
        </section>
    )
}

export default Portfolio