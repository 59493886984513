import React from 'react'
import { BsLinkedin } from 'react-icons/bs'
import { AiFillGithub } from 'react-icons/ai'
import { AiFillYoutube } from 'react-icons/ai'

const Headersocials = () => {
    return (
        <div className='header__socials'>
            <a href='https://www.linkedin.com/in/nickgastis/' target='blank'><BsLinkedin /></a>
            <a href='https://github.com/nickgastis/' target='blank'><AiFillGithub /></a>
            <a href='https://youtube.com' target='blank'><AiFillYoutube /></a>

        </div>
    )
}

export default Headersocials