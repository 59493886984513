import React from 'react'
import './about.css'
import aboutMe from '../../assets/aboutMe.png'
import { RiAwardFill } from 'react-icons/ri'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'


const about = () => {
    return (
        <section id='about'>
            {/* <h5>Get To Know</h5> */}
            <h2>About Me</h2>
            <div className='container about__container'>
                <div className='about__me'>
                    <div className='about__me-image'>
                        <img src={aboutMe} alt='About Image'></img>

                    </div>
                </div>
                <div className='about__content'>
                    <div className='about__cards'>
                        <article className='about__card'>
                            <RiAwardFill className='about__icon' />
                            <h5>Experience</h5>
                            <small>1+ Years Working </small>
                        </article>

                        <article className='about__card'>
                            <FiUsers className='about__icon' />
                            <h5>Clients</h5>
                            <small>5+</small>
                        </article>

                        <article className='about__card'>
                            <VscFolderLibrary className='about__icon' />
                            <h5>Projects</h5>
                            <small>20+ Completed </small>
                        </article>
                    </div>
                    <div>
                        <p>As a recent graduate from FlatIron School, I am a highly motivated and skilled full-stack developer looking for full-time employment opportunities. With experience in developing React and Flask applications, I am confident in my ability to deliver high-quality, scalable, and user-friendly software!</p>
                        <a href='#contact' className='btn btn-primary'>Let's Talk</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default about